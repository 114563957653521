.body-quienes-somos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  text-align: left;
  flex-direction: row;
  margin-bottom: 40px;
}

.text-content {
  flex: 1;
  font-size: 1.2rem;
  max-width: 40%;
  margin: 0 auto;
  text-align: left;
  margin-left: 10%;
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  transition: opacity 1s;  /* Transición suave de la opacidad */
  
}

.image-content img {
  max-width: 60%;
  height: auto;
  margin-right: 20%;
  border-radius: 10pt;

}
/* Media query para dispositivos móviles */

@media (max-width: 768px) {
  .body-quienes-somos {
    flex-direction: column;
  }

  /* Para mostrar primero la imagen */
  .image-content {
  order: -1; 
  }

  .text-content, .image-content {
    max-width: 100%;
    margin: 0;
  }

    .image-content img {
      max-width: 100%; /* La imagen se ajusta al 100% del ancho del contenedor */
      margin-right: 0; /* Eliminar el margen derecho en dispositivos más pequeños */
    }
  }
