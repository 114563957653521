.user-management {
  font-family: 'Arial', sans-serif;
  margin: 0 auto;
  max-width: 100%;
  height: 60vh;
  justify-content: center; /* Centra horizontalmente */

  color: #ffffff;
}
.user-table th {
  color: #ffffff;
  text-align: center;
}
.user-management h2 {
  color: #ffffff;
}

.user-table-container {
  overflow-x: auto; /* Habilita el desplazamiento horizontal */
}

.user-table {
  width: a; /* Cambiado de 100% a auto para permitir que la tabla se expanda según el contenido */
  border-collapse: collapse;
  margin-top: 10px;
  border: 1px solid #e07a2f;
border-radius: 30px;
  background-color: #4d4d4d;
}


.user-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.user-table th {
  background-color: #8f3b1b;
}

.user-table tr:nth-child(even) {
  background-color: #383838;
}

.user-table tr:hover {
  background-color: #f1f1f1;
  color: black;
}

.edit-button,
.delete-button {
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-button {
  background-color: #4CAF50;
  color: white;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.error {
  color: red;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .user-table {
    width: 100%; /* Cambiar de auto a 100% para que la tabla sea de ancho completo en dispositivos móviles */

  }

  .user-management{
    padding: 20px;
    overflow-x: scroll;


  }
  .user-table th,
  .user-table td {
    padding: 5px;
  }

  .edit-button,
  .delete-button {
    padding: 10px 20px;
    font-size: 16px;
  }
}
