@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;1,200&display=swap');

.forgot-password-form {
    width: 100%;
    max-width: 400px;
    margin: 3.5% auto;  /* Reducido de 10% a 5% para subir el formulario */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(255, 145, 0, 0.1);
    border: 1px solid #e07a2f;
    border-radius: 10px;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.forgot-password-form h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    color: #ccc;
}

.forgot-password-form .input-group {
    margin-bottom: 1rem;
}

.forgot-password-form label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
}

.forgot-password-form input[type="email"] {
    width: 100%;
    padding: 10px;
    border: 1px solid  #e07a2f;;
    border-radius: 10px;
    font-size: 1rem;
}

.forgot-password-form button {
    width: 100%;
    padding: 10px 15px;
    background: linear-gradient(to bottom, #8f3b1b 0%, #e07a2f 50%, #8f3b1b 100%);    
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
}

.forgot-password-form button:hover {
    background: linear-gradient(to bottom, #8f3b1b 0%, #b34200 50%, #8f3b1b 100%);    
}


@media (max-width: 768px) {
    .forgot-password-form {
        width: 100%;
        margin: 60px auto;
        padding: 15px;
    }
   .body{
    padding: 20px;
   }
}