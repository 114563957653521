@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;1,200&display=swap');

.sidebar {
  width: 60px;
  background-color: black;
  position: fixed;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  height: 100%;
  top: 0;
  border-right: 2px solid var(--principal-button-color);
  left: 0;
  transition: all 0.2s;
  z-index: 1;
 }

 .sidebar:hover {
  width: 200px;
 }
 
 .sidebar-icon {
  padding: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  transition: background-color 0.2s, color 0.2s;
  /* Include color transition */
  cursor: pointer;
 }
 
 /* Cambiamos el color de fondo y el color del ícono/texto al hacer hover */
 .sidebar-icon:hover {
  background-color: #666;
 }
 
 .sidebar-icon:hover svg {
  color: #fff;
 }
 
 .sidebar-icon:hover .sidebar-text {
  color: #fff;
 }
 
 .sidebar-icon svg {
  margin-right: 10px;
 }
 
 .sidebar-text, .submenu {
  display: none;
  flex-grow: 1;
 }


 .submenu-item {
  list-style: none;

  padding: 10px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  flex-grow: 1;
 }
 .submenu-item:hover{
  background: var(--primary-button-color);
 }
 .sidebar:hover .sidebar-text,.submenu {
  display: block;
 }
 

.content-area,
 .content-area-expanded {
  margin-left: 60px;
  padding: 20px;
  display: flex;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
  transition: margin-left 0.3s;
  /* Agrega transición suave al cambiar el ancho de la barra lateral */
  height: calc(100vh - 60px);
  /* Se ajusta a la altura del encabezado */
 }
 
 .content-area-expanded {
  margin-left: 300px;
}
.last-profile{
  display: none;
}
 

 .user-info {
  width: 80%;
  padding: 20px;
  border: 1px solid #8f3b1b;
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
 
 .user-info h2 {
  margin-bottom: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

 }
 
 .user-info p {
  margin: 8px 0;
  font-size: 16px;
 }
 
 .user-info p span {
  font-weight: bold;
 }
 
 /* Agregamos estilos para el playlist (ajústalo según tu implementación) */
 .playlist {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 20px;
 }
 
 .playlist-item {
  margin-right: 10px;
  cursor: pointer;
 }
 
 @media (max-width: 768px) {
  .sidebar {
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 55px;
    position: fixed;
    top: 0%;
 
    justify-content: space-around;
  }
.sidebar-text {
    display: none !important;
    flex-grow: 1;
   }
   .icons{
    display: block;
   }

  .sidebar:hover {
    
    width: 80% !important;
  }

  .content-area,
  .content-area-expanded {
    

    margin-left: 0px;
    padding: 0;
    width: 100% !important;
  }


.item{
  display: block;
  
}

  
/* Estilos para los subítems del menú */
.submenu {
  list-style: none;
  position: absolute;
  top: 110%; /* Alinear el submenú debajo del elemento padre */
  right: 0;
  text-align:  start;
  padding: 0;
  margin: 0;
  

  background-color: var(--navbar-color);
  border-radius: 5px; /* Bordes redondeados */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
}

/* Estilos para los elementos del submenú */
.submenu-item {
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 0;
  
}

/* Mostrar el submenú cuando el elemento principal del menú está activo */
.sidebar-icon:hover .submenu {
  display: block;
}
  .last{
    border-top: 1px solid #dddddd33;
  }

  .last-profile{
    display: flex;
  }
 }