@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;1,200&display=swap');

.navbar {
  background-color: black;
  padding: 0.5rem 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  align-items: center;
  position: relative; 

}

.navbar-logo {
  height: 50px; 
  margin-right: 1rem;
  border-radius: 8px;
}

.navbar-title {
  color: white;
  font-weight: bold;
  margin-right: 1rem;
  font-size: 0.8rem; /* Ajusta este valor según prefieras. */
}

.navbar-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 2rem;
  transition: all 0.3s ease;
  margin-left: auto; /* Esto mueve las opciones más a la derecha */
}

.navbar-list li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: color 0.3s ease;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.navbar-list li a:hover {
  color: #ddd;
  background-color: #5c2705;
}

@media (max-width: 768px) {
  .navbar-list {
    display: none;
    flex-direction: column;
    position: absolute;
    right: 0;
    gap: 0;
    top: 100%;
    background-color: var(--navbar-color);

    width: 60%;
    border-radius: 0 0 0 10px ;
    z-index: 10;
    
  }

  .navbar-list.active {
    display: flex;



  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
   

  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
  }
  .navbar-list li  {
padding: 10px;
width: 100%;
text-decoration: none;
border-bottom: 1px solid #dddddd33;

  }
  
  .navbar-list li a {
    display: block;
    border-radius: 0%;


  }

  .navbar-list li:hover {
    color: #ddd;


    background-color: #5c2705;
    
  }

  
}
