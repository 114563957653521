
.dashboard-container {
  width: 100%;
  padding: 1px;
}

.header {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-between; /* Cambiar a espacio entre para colocar los elementos a los extremos */
  align-items: center; /* Centrar verticalmente los elementos */
  height: 40px; /* Establecer una altura específica */
  position: fixed; /* Agregar posición fija para que el encabezado se mantenga en la parte superior */
  z-index: 1; /* Asegurar que el encabezado esté en primer plano */

}

.view-selector
{
  width: 10%;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  position: absolute;
  justify-content: flex-end;
  font-weight: bolder;
  font-family: "Raleway", sans-serif;
  right: 20px; /* Posicionar el selector de vista a la derecha */
z-index: 1000;

}

.h1-container {
margin: 0 auto;

}

.logout-button {
  background: #e07a2f;    
  color: white;
  margin-left: 1px;
  border: none;
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {

  .sidebar-text {
    display: none !important;
    flex-grow: 1;
   }

  .h1-container {
display: none;
  }
  .header{
    height: 55px;
  }
 
  .view-selector{
    z-index: 10000;
    position: absolute;
  }
  }
  