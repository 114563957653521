 .header-home { 
  padding: 20px 0;
  text-align: center;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
/*
@keyframes pulse {
  0% {
    font-size: 1.5rem;
    opacity: 0.7; /* Agregado para una transición suave
  }
  50% {
    font-size: 2.5rem;
    opacity: 1; /* Agregado para una transición suave 
  }
  100% {
    font-size: 1.5rem;
    opacity: 0.7; /* Agregado para una transición suave 
  }
}

.header-home h1 {
  animation: pulse 5s infinite; /* 5s es la duración de una ciclo de la animación, puedes ajustar a tu gusto 
} */ 


