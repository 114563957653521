.sidebar-user {
  width: 60px;
  background-color: black;
  position: fixed;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  height: 100%;
  top: 0;
  border-right: 2px solid var(--principal-button-color);
  left: 0;
  transition: all 0.2s;
  z-index: 1;
}

.sidebar:hover {
  width: 200px;
}

.sidebar-icon {
  padding: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  transition: background-color 0.2s, color 0.2s;
  cursor: pointer;
}

.sidebar-icon:hover {
  background-color: #666;
}

.sidebar-icon:hover svg {
  color: #fff;
}

.sidebar-icon:hover .sidebar-text {
  color: #fff;
}

.sidebar-icon svg {
  margin-right: 10px;
}

.sidebar-text {
  display: none;
  flex-grow: 1;
}

.sidebar:hover .sidebar-text {
  display: block;
}

.content-area,
.content-area-expanded {
  margin-left: 60px;
  padding: 20px;
  display: flex;
  margin-bottom: 10%;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
  transition: margin-left 0.3s;

  height: calc(100vh - 60px);
}

.content-area-expanded {
  margin-left: 300px;
}

.user-info {
  padding: 20px;
  width: 100%;
  border: 1px solid #8f3b1b;
  border-radius: 30px;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-info h2 {
  margin-bottom: 20px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.user-info p {
  margin: 8px 0;
  font-size: 16px;
}

.user-info p span {
  font-weight: bold;
}

.forms-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.form-profile,
.form-address,
.form-phones {
  background: rgb(22, 22, 22);

  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  flex-basis: calc(33% - 20px);
  min-width: 250px;
}

.form-profile h3,
.form-address h3,
.form-phones h3 {
  margin-bottom: 10px;
  font-family: "Raleway", sans-serif;
}

input:disabled {
  background-color: #e9ecef;
  color: #6c757d;
}

input {
  display: block;
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #e07a2f;
  border-radius: 4px;
}

button {
  background-color: #e07a2f;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #8f3b1b;
}
.last-profile {
  display: none;
}

@media (max-width: 768px) {
  .useri {
    margin-top: 0;
  }
  .sidebar-text {
    display: none;
    flex-grow: 1;
  }
  .sidebar-user {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    height: 55px;
    position: fixed;
    border: none !important;

    top: 0%;
    justify-content: space-around;
  }

  .sidebar-user.active {
    width: 80% !important;
  }
  .sidebar-user.active:hover {
    width: 80% !important;
  }
  .content-area,
  .content-area-expanded {
    margin-left: 0px;
    padding: 0;
    width: 100% !important;
  }
  .last-profile {
    display: flex;
  }
}
