/* Estilo del contenedor principal */

.videos-container{
  background-size: cover; /* Ajustar la imagen al tamaño de la pantalla */
  background-position: center; /* Centrar la imagen */
  background-repeat: repeat-y; /* No repetir la imagen */
  border: 1px solid #8f3b1b;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.966);
  border-radius: 10px;
  justify-content: flex-start;
  position: relative; /* Agregamos posición relativa */
  }
.bodyVideos{
  width: 100%;
  margin-top: 20px;
height: 100vh;



}

.video-header{
margin-left:  20px;
}

.react-playerV{
  padding: 0%;
 margin-bottom: 3%;
width: 100% !important; 


}
.main-content {
  width: 70%;
}

/* Estilo de la barra lateral de videos */
.SideBarVideos {
  width: 30%;
  background-image: url('../Videos/abstract-black-background-minimal_706163-27661.jpg');
  background-size: cover; /* Ajustar la imagen al tamaño de la pantalla */
  background-position: center; /* Centrar la imagen */
  background-repeat: repeat-y; /* No repetir la imagen */
  padding: 16px;
  display: block;
  overflow-y: auto;
  border-left: 1px solid #8f3b1b;
  /* background: linear-gradient(to bottom, #c2d7e4, #e0e9f1); Gradiente azul grisáceo */
  margin-left: auto; /* Mueve el SideBarVideos a la derecha */
  align-self: flex-start;
  position: absolute; /* Cambiamos a posición absoluta */
  top: 0; /* Alineamos en la parte superior */
  right: 0; /* Alineamos a la derecha */
  height: 100%; /* Ocupa toda la altura de videos-container */
}

.SideBarVideos button {
  width: 100%;
  padding: 10px;
  background-color: black;
  border: none;
  border-radius: 10pt;
  border-bottom: 1px solid #ddd;
  margin-bottom: 4px;
  margin-top: 4px;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s;
  color: aliceblue;
}

.SideBarVideos button:hover {
  background-color: #3b2c2c;
}

.video-item {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  border-radius: 10pt;
  background-color: #474444;
  color: white;
  border-bottom: 1px solid #f5f5f5;

  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.video-item:hover {
  background-color: #9b9b9b;
}



.video-item span {
  flex-grow: 1;
}

.video-item > span:last-child {
  margin-left: auto;
  color: rgb(255, 255, 255);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-width: 400px;
  width: 90%;
}

.add-video-button {
  width: 50px;
  padding: 2px 5px;
  border: none;
  cursor: pointer;
  text-align: right;
  background: linear-gradient(to bottom, #8f3b1b 0%, #e07a2f 50%, #8f3b1b 100%);    
  transition: background-color 0.3s;
  color: #fff;
  font-size: 12px;
  margin: 0;
  display: inline-block;
}

.add-video-button:hover {
  background: linear-gradient(to bottom, #8f3b1b 0%, #b34200 50%, #8f3b1b 100%);    
}


.add-video-button::before {
  content: "+";
  margin-right: 5px;
}


.sequence-box {
  display: inline-block;
  padding: 5px 10px;
  background-color: #645757;
  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
}

.add-section-button {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
  position: sticky;
  bottom: 16px;
}
.react-tabs__tab--selected {
  background: linear-gradient(to bottom, #8f3b1b 0%, #e07a2f 50%, #8f3b1b 100%);    
  border: 1px solid #e07a2f;

  color: white;
}
.react-tabs__tab {
  transition: background-color 0.3s ease-in-out;
}

.rea
.add-section-button:hover {
  background-color: #141d16;
}

.add-section-button::after {
  content: " Añadir Nueva Sección";
  margin-right: 5px;
}
p{
  white-space: pre-line;
  
}
.watched{
  padding: 5px;
}
.video-content{
 
  max-height: 150px; /* Altura máxima que deseas antes de que aparezca la barra de desplazamiento */
  overflow-y: auto; /* Agrega una barra de desplazamiento vertical si es necesario */
  padding: 10px; /* Añade espacio alrededor del contenido */
  max-width: 100%; /* Haz que ocupe todo el ancho disponible */
  box-sizing: border-box; /* Incluye el borde en el cálculo del ancho */
  margin-top: 1px;
 /* overflow: hidden;*/
}

@media (max-width: 768px) {
  .videos-container {
    flex-direction: column;
    justify-content: center;
    
  }
  .bodyVideos{
    width: 100%;
    margin-top: 65px;
    }
  .main-content {
    width: 100%;
    
  }
  
  .react-playerV{
    width: 100% !important;
    
              height:35vh!important;;
    margin: 0%;
  
  }

  /* Otros estilos para pantallas pequeñas */

  .SideBarVideos {
    position: initial;
    width: 100%;
    max-height: 300px;
    margin-bottom: 80px;
    overflow-y: scroll;
  }
  .video-header{
display: none;
}
  .videos-container{
    width: 100%;
   
    border: 1px solid #ccc;
    margin-bottom: 25px;
    overflow-y: auto;
  }

 
    
  footer{
    max-width: 100%;
  }
 
  
}
