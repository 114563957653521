/* Estilos generales */
.user-management {
  font-family: 'Arial', sans-serif;
  color: #333;
}

/* Estilos para la tabla */
.user-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden; /* Para bordes redondeados en la tabla */
}
.userTable{
  width: 60%;
  padding: 20px;
}

.user-table th,
.user-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.user-table th {
  background-color: #f2f2f2;
  color: #333;
}

.user-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.user-table tr:hover {
  background-color: #f1f1f1;
}

/* Estilos para los iconos */
.icon {
  font-size: 1.5rem;
  color: #ff7b00;
  cursor: pointer;
  margin: 0 10px;
}

.icon:hover {
  color: #cc8a28;
}

/* Estilos para los modales */
.modal {
  background-color: #414141;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: auto;
}

/* Botones del modal */
.modal button {
  padding: 10px 20px;
  margin-top: 10px;
  margin-left: 6px;
  border: none;
  border-radius: 10px;
  background-color: #ffbb00;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.modal button:hover {
  background-color: #8f3b1b;
}

/* Responsividad */
@media (max-width: 768px) {
  .modal {
    width: 90%;
  }
}

/* Estilos para el formulario */
.form-group {
  margin-bottom: 15px;
}

.input-field {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Botones del formulario */
.submit-button {
  background-color: #28a745;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submit-button:hover {
  background-color: #218838;
}
