@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;1,200&display=swap');

.reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom : 40px;
    height: 85vh;
  }
  
  .reset-password-form {
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 20px;
    font-family: "Raleway", sans-serif;

    border: 1px solid #8f3b1b;
    border-radius: 30px;
    background-color: #0000007c;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .reset-password-form h2 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-bottom: 20px;
    text-align: center;

  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #e07a2f;
    border-radius: 10px;
  }
  
  .error-message {
    color: red;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

    margin: 10px 0;
    font-size: 0.9em;

  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background: linear-gradient(to bottom, #8f3b1b 0%, #e07a2f 50%, #8f3b1b 100%);    
    color: white;
    font-family: "Raleway", sans-serif;
    font-weight: 800;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .submit-button:hover {
    background: linear-gradient(to bottom, #8f3b1b 0%, #b34200 50%, #8f3b1b 100%);    
  }
  