.body-anuncios {
  padding: 20px;
  background: rgba(0, 0, 0, 0.89);
  /* border: 1px solid rgb(90, 56, 32); */
  /* Borde sutil */
  border-radius: 30px;
  /* Bordes redondeados para un aspecto moderno */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Sombra suave para dar profundidad */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  width: 80%;
  /* Ancho máximo para mantener la legibilidad */
  margin: 40px auto;
  /* Centrado con margen vertical */
  text-align: left;
  /* Alineación del texto a la izquierda para facilitar la lectura */
  margin-bottom: 60px;
  height: 100%;
}

.body-anuncios h2 {
  color: #eeeeee;
  /* Color azul oscuro para el título de sección */
  font-size: 28px;
  text-align: center;

  /* Tamaño del texto grande para el título */
  margin-bottom: 20px;
  /* Espacio después del título */
}

.body-anuncios p {
  color: #ffffff;
  /* Color gris oscuro para el texto */
  font-size: 16px;
  /* Tamaño del texto para una lectura cómoda */
  line-height: 1.6;
  /* Espacio entre líneas para mejorar la legibilidad */
  margin-bottom: 20px;
  /* Espacio entre párrafos */
}

.body-anuncios strong {
  color: #d3792f;
  /* Utilizar el mismo color rojo para elementos destacados */
}

.steps-to-success {
  margin-top: 30px;
  /* background: rgb(143, 59, 27, 0.8); */
  /* Fondo ligeramente gris para la sección de pasos */
  /* border: 3px solid #915c31; */
  margin: auto;
  padding: 20px;
  border-radius: 30px;
  /* Bordes redondeados */
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.steps-to-sucess-title {
  color: white;
  font-size: 24px;
  margin-bottom: 15px;
  text-align: start;
}

.steps-to-success .card {
  min-height: 10rem;
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  text-align: start;
  border: 2.5px solid rgb(90, 56, 32);
  border-radius: 2vmin;
  padding: 10px;
}
/* 
@media (max-width: 1050px) {
  .steps-to-success .card {
    width: 50%;
  }
} */

.step-number {
 
  padding: 3px 10px 3px 10px;
  text-align: center;
  background-color: #915c31;
  border-radius: 50%;
}

.body-anuncios .contact-info {
  background: rgba(248, 248, 248);
  /* Fondo ligeramente diferente para resaltar la sección */
  padding: 20px;
  width: 100%;
  /* border: 4px solid #974400; */
  border-radius: 30px;
  /* Bordes redondeados */
  margin-top: 30px;
  /* Espacio antes de la sección de contacto */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contact-info {
  margin-top: 30px;
  margin: auto;
}

.body-anuncios .contact-info h3 {
  color: #000000;
  font-size: 22px;
  margin-bottom: 15px;

}

.contact-info p {
  color: #000000;
}

.body-anuncios .contact-info p {
  margin-bottom: 10px;
  /* Espacio entre elementos de contacto */
}

.body-anuncios a {
  color: #1a0dab;
  /* Color azul para los enlaces, buena visibilidad */
  text-decoration: none;
  /* Sin subrayado para mantener la limpieza */
}

.body-anuncios a:hover {
  text-decoration: underline;
  /* Subrayado al pasar el cursor para indicar interactividad */
}

@media (max-width: 768px) {
  .steps-to-success {
    width: 100%;
  }

  .body-anuncios .contact-info {
    width: 100%;
  }

  .body-anuncios {
    margin-bottom: 100px;
  }
}

.separator {
  height: 2px;
  width: 100%;
  background-color: #ffffff7c;
  margin: 5vmin 0;
}

@media (max-width: 750px) {
  .steps-to-success .card {
    width: 100%;
  }

  .contact-info {
    flex-direction: column;
  }
}
