@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;1,200&display=swap');

:root{
  --primary-button-color: linear-gradient(to bottom, #8f3b1b 0%, #e07a2f 50%, #8f3b1b 100%);  
  --principal-button-color: #e07a2f;
  --principal-hover-color: linear-gradient(to bottom, #8f3b1b 0%, #b34200 50%, #8f3b1b 100%);
  --navbar-color: rgb(32, 32, 32);

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: left;
  background-image: url('./abstract-black-background-minimal_706163-2766.jpg');
  background-size: cover;
  /* Ajustar la imagen al tamaño de la pantalla */
  background-position: center;
  /* Centrar la imagen */
  background-repeat: repeat-y;
  /* No repetir la imagen */
  background-attachment: fixed;
  /* Fijar la imagen en la posición */


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  max-width: 1200px;
  margin: 0 auto;


}

@media (max-width: 768px) {

  body {
    width: 100%;

  }
}