.footer-anuncios {
    background-color: black; 
    color: white; 
    text-align: center;
    padding: 5px 0;
    position: fixed;
    font-size: 8pt;
    bottom: 0;
    width: 100%;
}
